<template>
  <div class="map-container">
    <div class="rail-container">
      <gmap-map ref="map" map-type-id="terrain" style="width: 100%; height: 500px" :center="mapCenter">
        <gmap-polyline v-bind:options="{ strokeColor: '#ff0000' }" :path="aSortedWaypoints" :stroke-opacity="1.0" :stroke-weight="2"></gmap-polyline>
        <gmap-marker
          class="map-station-marker"
          v-for="(marker, index) in aSortedShedulePanel"
          :key="index"
          :position="{ lat: parseFloat(marker.stationLatitude), lng: parseFloat(marker.stationLongitude) }"
          :draggable="false"
          :icon="generateIconSvg(index + 1)"
          @mouseover="toggleInfoWindow(marker, index)"
          @mouseout="toggleInfoWindow(marker, index)"
        >
          <gmap-info-window v-if="currentMarker === marker">
            <div id="station-info-content">
              <div class="station-info-window-header">
                <font-awesome-icon icon="fa-solid fa-train" />
                <h3 class="firstHeading">{{ $t("shipment.tracking.station") }} {{ marker.stationName }}</h3>
              </div>
              <div class="is-flex info-row">
                <h3>{{ formatTextField(transportNumber) }}</h3>
              </div>
              <div id="bodyContent">
                <h5>
                  {{ $t("shipment.tracking.arrivalDate") }}: {{ formatDateField(marker.dateFactArrival) }}
                  <br />
                  {{ $t("shipment.tracking.arrivalTime") }} :
                  {{ formatTimeField(marker.dateFactArrival) }}
                  <br />
                  {{ $t("shipment.tracking.distance") }}
                  {{ $t("txt.km", [marker.remainingDistance]) }}
                </h5>
              </div>
            </div>
          </gmap-info-window>
        </gmap-marker>
        <gmap-marker v-if="aSortedShedulePanel.length > 0" :position="currentPosition" :zIndex="20"></gmap-marker>
      </gmap-map>
      <div class="shedule displayOnMobile">
        <div v-for="(station, indexStation) in aSortedShedulePanel" :key="indexStation">
          <div class="station">
            <div class="station-frame">
              <div class="station-num-frame" :class="indexStation + 1 === waypoints.length ? 'current-station' : ''">
                <div class="station-num">{{ indexStation + 1 }}</div>
              </div>
              <div v-if="indexStation !== waypoints.length - 1" class="arrow">
                <div></div>
              </div>
            </div>
            <div class="station-info">
              <div class="station-name">{{ station.stationName }}</div>
              <div class="arrival-item">
                <label>{{ $t("shipment.tracking.arrivalDate") }}</label>
                <div>{{ formatDateField(station.dateFactArrival) }}</div>
              </div>
              <div class="arrival-item">
                <label>{{ $t("shipment.tracking.arrivalTime") }}</label>
                <div>{{ formatTimeField(station.dateFactArrival) }}</div>
              </div>
              <div class="arrival-item">
                <label>{{ $t("shipment.tracking.distance") }}</label>
                <div>{{ $t("txt.km", [station.remainingDistance]) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hideOnDesktop">
        <b-button class="shedule-btn" @click="bottomSheetOpen">{{ $t("shipment.tracking.btn.railWayShedule") }}</b-button>
        <vue-bottom-sheet-vue2 ref="myBottomSheet">
          <div class="shedule">
            <div v-for="(station, indexStation) in aSortedShedulePanel" :key="indexStation">
              <div class="station">
                <div class="station-frame">
                  <div class="station-num-frame" :class="indexStation + 1 === waypoints.length ? 'current-station' : ''">
                    <div class="station-num">{{ indexStation + 1 }}</div>
                  </div>
                  <div v-if="indexStation !== waypoints.length - 1" class="arrow">
                    <div></div>
                  </div>
                </div>
                <div class="station-info">
                  <div class="station-name">{{ station.stationName }}</div>
                  <div class="arrival-item">
                    <label>{{ $t("shipment.tracking.arrivalDate") }}</label>
                    <div>{{ formatDateField(station.dateFactArrival) }}</div>
                  </div>
                  <div class="arrival-item">
                    <label>{{ $t("shipment.tracking.arrivalTime") }}</label>
                    <div>{{ formatTimeField(station.dateFactArrival) }}</div>
                  </div>
                  <div class="arrival-item">
                    <label>{{ $t("shipment.tracking.distance") }}</label>
                    <div>{{ $t("txt.km", [station.remainingDistance]) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-bottom-sheet-vue2>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import svgToDataURL from "svg-to-dataurl";
import * as VueGoogleMaps from "vue2-google-maps";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";

export default {
  props: {
    waypoints: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      zoom: 8, // Масштаб карты
      blinkingAnimation: null,
      markerAnimation: null,
      isLoading: false,
      currentPosition: { lat: 0, lng: 0 },
      isMapBlur: true,
      currentMarker: null,
      aSortedWaypoints: []
    };
  },
  watch: {
    waypoints() {
      if (this.waypoints.length > 0) {
        this.displayRailwayTracking();
      } else {
        this.isLoading = false;
      }
    }
  },
  computed: {
    bounds() {
      // Define the LatLngBounds object
      const latlngbounds = new window.google.maps.LatLngBounds();

      let lats = Object.values(this.aSortedWaypoints).map((coord) => coord?.lat);
      let lngs = Object.values(this.aSortedWaypoints).map((coord) => coord?.lng);
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);

      latlngbounds.extend({ lat: minLat, lng: maxLng }); //SouthEast
      latlngbounds.extend({ lat: maxLat, lng: minLng }); //NorthWest

      return latlngbounds;
    },
    aSortedShedulePanel() {
      let aShedule = this.waypoints;
      let sortedData;
      if (aShedule.toSorted) {      
        sortedData = aShedule.toSorted((a, b) => {
          const dateA = new Date(a.dateFactArrival);
          const dateB = new Date(b.dateFactArrival);
          return dateA - dateB;
        })}
      else {
        sortedData = [...aShedule].sort((a, b) => {
          const dateA = new Date(a.dateFactArrival);
          const dateB = new Date(b.dateFactArrival);
          return dateA - dateB;
        })}
      return sortedData;      
    },
    mapCenter() {
      return this.currentPosition;
    }
  },
  mounted() {
    this.isLoading = true;
  },
  methods: {
    bottomSheetOpen() {
      this.$refs.myBottomSheet.open();
    },
    generateIconSvg(iNumber) {
      const size = 30;
      const strokeWidth = iNumber === 1 ? 3 : 0;
      const bgColor = "#ffffff";
      const textColor = "#000000";
      const number = iNumber;
      const svg = `
        <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 4}" fill="${bgColor}" stroke-width="${strokeWidth}" stroke="#FF0000" />
          <text x="${size / 2}" y="${size / 2}" fill="${textColor}" font-family="Arial" font-size="${size / 2}" text-anchor="middle" dominant-baseline="middle">${number}</text>
        </svg>
      `;
      return svgToDataURL(svg);
    },
    displayRailwayTracking() {
      this.aSortedWaypoints = [];
      this.aSortedShedulePanel.forEach((oPoint) => {this.aSortedWaypoints.push({
        lat: parseFloat(oPoint.stationLatitude),
        lng: parseFloat(oPoint.stationLongitude)
       })});
      this.$refs.map.$mapPromise
        .then(() => {
          this.$refs.map.$mapObject.fitBounds(this.bounds);
        })
        .finally(() => {
          this.currentStation = this.aSortedShedulePanel[this.aSortedShedulePanel.length - 1];
          this.currentPosition = { lat: parseFloat(this.currentStation.stationLatitude), lng: parseFloat(this.currentStation.stationLongitude) };
          this.isLoading = false;
          this.isMapBlur = false;
        });      
    },
    toggleInfoWindow(marker, index) {
      if (this.currentMarker?.stationName === marker?.stationName) {
        this.currentMarker = null;
      } else {
        this.currentMarker = marker;
      }
    },
    getDirections(oFirstStation, oSecondStation) {
      let directionsService = new window.google.maps.DirectionsService();
      let directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(this.$refs.map.$mapObject);
      directionsRenderer.setOptions({
        polylineOptions: {
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 4
        },
        markerOptions: {
          visible: false,
          clickable: false
        },
        suppressMarkers: false,
        suppressInfoWindows: true
      });

      directionsService
        .route(
          {
            origin: { lat: parseFloat(oFirstStation.stationLatitude), lng: parseFloat(oFirstStation.stationLongitude) },
            destination: { lat: parseFloat(oSecondStation.stationLatitude), lng: parseFloat(oSecondStation.stationLongitude) },
            travelMode: window.google.maps.TravelMode.TRANSIT,
            transitOptions: {
              modes: [window.google.maps.TransitMode.RAIL]
            }
          },
          (response, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              for (let i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                if (response.routes[0].legs[0].steps[i]?.transit) {
                  delete response.routes[0].legs[0].steps[i].transit;
                }
              }
              directionsRenderer.setDirections(response);
            }
            else if (status === window.google.maps.DirectionsStatus.ZERO_RESULTS) {
              directionsService
                .route({
                    origin: oFirstStation.stationCode+" "+oFirstStation.stationName+" railway station",
                    destination: oSecondStation.stationCode+" "+oSecondStation.stationName+" railway station",
                    travelMode: window.google.maps.TravelMode.TRANSIT,
                    transitOptions: {
                      modes: [window.google.maps.TransitMode.RAIL]
                    }
                  },
                  (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                      for (let i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                        if (response.routes[0].legs[0].steps[i]?.transit) {
                          delete response.routes[0].legs[0].steps[i].transit;
                        }
                      }
                      directionsRenderer.setDirections(response);
                    }                  
                })
                .catch((e) => {
                  this.errorMessage(e);
                });
            }
          }
        )
        
    }
  },
  components: {
    "gmap-map": VueGoogleMaps.Map,
    "gmap-marker": VueGoogleMaps.Marker,
    "gmap-info-window": VueGoogleMaps.InfoWindow,
    VueBottomSheetVue2
  }
};
</script>

<style lang="scss">
.vue-map-container {
  height: 35rem !important;
}

.rail-container {
  display: flex;
  gap: 1rem;
  .shedule {
    max-height: 80vh;
    padding: 1rem;
    overflow-y: scroll;
    position: relative;
    min-width: 15rem;
    height: 35rem;

    .station {
      display: flex;

      .station-frame {
        display: flex;
        flex-direction: column;

        .station-num-frame {
          border-radius: 50%;
          background: #2c3e50;
          width: 2rem;
          height: 2rem;

          .station-num {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: #ffffff;
          }
        }

        .current-station {
          -webkit-box-sizing: border-box;
          border-radius: 50%;
          border: 0.2rem solid #2c3e50;
          background: #ffffff;

          .station-num {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            color: #2c3e50;
          }
        }

        .arrow {
          position: relative;
          margin-left: auto;
          margin-right: auto;
        }

        .arrow div {
          position: relative;
          width: 0.2rem; //ширина стрелки
          height: 4rem; //длина стрелки
          background-color: #2c3e50;
        }

        .arrow div::before {
          content: "";
          position: absolute;
          width: 0.75rem;
          height: 0.2rem;
          bottom: 4px;
          right: -9px;
          background-color: #2c3e50;
          transform: rotate(135deg);
        }

        .arrow div::after {
          content: "";
          position: absolute;
          width: 0.75rem;
          height: 0.2rem;
          bottom: 4px;
          right: -1px;
          background-color: #2c3e50;
          transform: rotate(45deg);
        }
      }

      .station-name {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }

      .station-info {
        .arrival-item {
          display: flex;
          flex-direction: row;
          margin-left: 0.5rem;
          gap: 0.25rem;

          & > label::after {
            content: ": ";
          }

          div {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.station-info-window-header {
  display: flex;
  gap: 0.5rem;

  .firstHeading {
    padding-bottom: 0.3rem;
    font-weight: bold;
  }
}

.hideOnDesktop {
  display: none;
}
.shedule-btn {
  width: 100%;
}

.map-station-marker {
  z-index: 100;
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  .rail-container {
    flex-direction: column;
  }
  .hideOnDesktop {
    display: block !important;
  }
  .displayOnMobile {
    display: none;
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .vue-map-container {
    height: 15rem !important;
  }
  .displayOnMobile {
    display: block;
  }
}

.transit-container {
  display: none;
}
</style>
