export default {
  methods: {
    filterAndSortArray(oData, oParams) {
      if (oParams.sorters) {
        if (oParams.sorters.name.indexOf("date") > -1) {
          var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
          oData.sort((a, b) => {
            const aParamNames = oParams.sorters.name.split(",");
            a = this.getValueFromObject(a, aParamNames[0]) ? this.getValueFromObject(a, aParamNames[0]) : this.getValueFromObject(a, aParamNames[1]);
            b = this.getValueFromObject(b, aParamNames[0]) ? this.getValueFromObject(b, aParamNames[0]) : this.getValueFromObject(b, aParamNames[1]);
            if (new Date(a.replace(pattern, "$3-$2-$1")) < new Date(b.replace(pattern, "$3-$2-$1"))) return 1;
            if (new Date(a.replace(pattern, "$3-$2-$1")) > new Date(b.replace(pattern, "$3-$2-$1"))) return -1;
            return 0;
          });
        } else {
          if (oParams.sorters.name === "hasUnread") {
            oData.sort((a, b) => {
              a = this.getValueFromObject(a, oParams.sorters.name);
              b = this.getValueFromObject(b, oParams.sorters.name);
              if (a < b) return 1;
              if (a > b) return -1;
              return 0;
            });
          } else {
            oData.sort((a, b) => {
              a = this.getValueFromObject(a, oParams.sorters.name);
              b = this.getValueFromObject(b, oParams.sorters.name);
              if (a > b) return 1;
              if (a < b) return -1;
              return 0;
            });
          }
        }
      }

      if (oParams.filters.length) {
        oParams.filters.forEach((oFilter) => {
          if (oFilter.type === "rangedatepicker") {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              oDataProperty = this.$moment(oDataProperty).unix();

              if (oFilter.value1 && oFilter.value2) {
                return oDataProperty >= this.$moment(oFilter.value1).unix() && oDataProperty <= this.$moment(oFilter.value2).unix();
              } else if (oFilter.value1) {
                return oDataProperty >= this.$moment(oFilter.value1).unix();
              } else {
                return oDataProperty <= this.$moment(oFilter.value2).unix();
              }
            });
          } else if (oFilter.type === "dropdown") {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              return oFilter.value1.includes(oDataProperty);
            });
          } else {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              return oDataProperty?.toLowerCase().indexOf(oFilter.value1.toLowerCase()) > -1;
            });
          }
        });
      }

      return oData;
    },

    filterArray(oData, oParams) {
      if (oParams.filters.length) {
        oParams.filters.forEach((oFilter) => {
          if (oFilter.type === "rangedatepicker") {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              oDataProperty = this.$moment(oDataProperty).unix();

              if (oFilter.value1 && oFilter.value2) {
                return oDataProperty >= this.$moment(oFilter.value1).unix() && oDataProperty <= this.$moment(oFilter.value2).unix();
              } else if (oFilter.value1) {
                return oDataProperty >= this.$moment(oFilter.value1).unix();
              } else {
                return oDataProperty <= this.$moment(oFilter.value2).unix();
              }
            });
          } else if (oFilter.type === "dropdown") {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              return oFilter.value1.includes(oDataProperty);
            });
          } else if (oFilter.type === "multipleselect") {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              return oFilter.value1.some(value =>value[oFilter.name].includes(oDataProperty));
            });
          }else {
            oData = oData.filter((oElem) => {
              let oDataProperty = this.getValueFromObject(oElem, oFilter.name);
              return oDataProperty?.toLowerCase().indexOf(oFilter.value1.toLowerCase()) > -1;
            });
          }
        });
      }

      return oData;
    },

    sortArray(oData, oParam) {
      let bIsDateToSort = oParam.name.toLowerCase().indexOf("date") > -1;
      oData.sort((a, b) => {
        const aParamNames = oParam.name.split(",");
        a = this.getValueFromObject(a, aParamNames[0]) ? this.getValueFromObject(a, aParamNames[0]) : this.getValueFromObject(a, aParamNames[1]);
        b = this.getValueFromObject(b, aParamNames[0]) ? this.getValueFromObject(b, aParamNames[0]) : this.getValueFromObject(b, aParamNames[1]);
        if (bIsDateToSort) {
          a = a ? a : 0;
          b = b ? b : 0;
          return oParam.asc ? this.$moment(b).unix() - this.$moment(a).unix() : this.$moment(a).unix() - this.$moment(b).unix();
        }

        if (Number(a) && Number(b)) {
          a = Number(a);
          b = Number(b);
        }

        if (a < b) {
          return oParam.asc ? -1 : 1;
        }
        if (a > b) {
          return oParam.asc ? 1 : -1;
        }
        return 0;
      });

      return oData;
    },

    sortTracking(oData, oParam) {
      (oData || []).sort((a, b) => {
        a = this.getValueFromObject(a, oParam);
        b = this.getValueFromObject(b, oParam);
        return a - b;
      });
      return oData;
    },

    getStatusClass(sStatus, arrivalDateActual) {
      let sDefaultClass = "";
      if (arrivalDateActual && arrivalDateActual !== 'Unknown date') {
        return "success"
      }
      switch (sStatus) {
        case "ORDNOTSENT":
          sDefaultClass = "error";
          break;
        case "PROCESS":
          sDefaultClass = "warning";
          break;
        case "READY":
          sDefaultClass = "success";
          break;
        default:
          sDefaultClass = "default";
      }

      return sDefaultClass;
    },

    getValueFromObject(oObject, sStringValue) {
      if (sStringValue) {
        let aFieldParts = sStringValue.split(".");
        let oResultValue = oObject;

        aFieldParts.forEach((sKeyPart) => {
          oResultValue = oResultValue[sKeyPart];
        });

        return oResultValue;
      }
    },

    searchDataByFields(oData, aSearchFields, sSearchValue) {
      oData = oData.filter((oDataProperty) => {
        const aFields = aSearchFields.map((sSearchProp) => this.getValueFromObject(oDataProperty, sSearchProp));
        const aAppropriateValues = aFields.filter((sText) => sText?.toLowerCase().indexOf(sSearchValue.toLowerCase()) > -1);
        return aAppropriateValues.length > 0;
      });
      return oData;
    },

    getCardBreakpoints() {
      return {
        350: {
          itemsToShow: 1.2
        },
        480: {
          itemsToShow: 1.5
        },
        600: {
          itemsToShow: 2
        },
        720: {
          itemsToShow: 2.5
        },
        960: {
          itemsToShow: 3
        },
        1120: {
          itemsToShow: 4
        },
        1220: {
          itemsToShow: 4
        },
        1400: {
          itemsToShow: 5
        },
        1600: {
          itemsToShow: 6
        }
      };
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },

    updateHeader() {
      const bIsNavBack = this.$route.name !== "home";
      let sTitle = this.$t("title." + this.$route.name);
      this.$store.commit("setHeaderParams", {
        title: sTitle,
        isNavBack: bIsNavBack
      });
    },

    formatTextField(sText) {
      return sText ? sText : "—";
    },

    formatDecimalNumberField(sText) {
      return !isNaN(Number(sText))
        ? Number(sText)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)
        : "—";
    },

    formatDecimalInvertedNumberField(sText) {
      return !isNaN(Number(sText))
        ? Number(sText) * (-1)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1 `)
        : "—";
    },

    formatDateField(sText) {
      let oDate = this.$moment(sText);
      return sText && oDate.isValid() ? oDate.format("DD.MM.YYYY") : "—";
    },

    formatTimeFromNowField(sText) {
      let oDate = this.$moment(sText);
      return sText && oDate.isValid() ? oDate.fromNow() : "—";
    },

    formatTimeField(sText) {
      let oDate = this.$moment(sText);
      return sText && oDate.isValid() ? oDate.format("HH:mm") : "—";
    },

    formatDateTime(sText) {
      let oDate = this.$moment(sText);
      return sText && oDate.isValid() ? oDate.format("DD.MM.YYYY HH:mm") : "—";
    },

    formatTextStatus(sText) {
      return sText ? this.$t("shipment.status." + sText?.toLowerCase()) : "—";
    },

    formatterTableValue(sText) {
      if (this.$moment(sText, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]", true).isValid()) {
        return this.$moment(sText).format("DD.MM.YYYY")
      } else {
        return Number(sText) === 0 ? "—" : sText ;
      }
    },

    errorMessage(sText) {
      if (sText) {
        this.$buefy.toast.open({
          duration: 3000,
          message: sText.message ? sText.message : sText,
          type: "is-warning",
          position: "is-top"
        });
      }
    },

    successMessage(sText) {
      this.$buefy.toast.open({
        duration: 3000,
        message: sText,
        type: "is-success",
        position: "is-top"
      });
    },

    getWorkDuration(sFirstContract) {
      if (!sFirstContract) {
        return 0;
      }
      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      const sContractDate = new Date(sFirstContract.replace(pattern, "$3-$2-$1"));
      return this.$moment(sContractDate).isValid() ? this.$moment(sContractDate).toNow(true) : "—";
    },

    changeLocale(sLocale) {
      this.$i18n.locale = sLocale;
      this.$moment.locale(sLocale);
    },

    paginate(array, pageSize, pageNumber) {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },

    getColorPalette(iLength) {
      const aColors = ["#2C3E50", "#F26C41", "#16657A", "#DE546F", "#008F92", "#B0538C", "#38B892", "#745790", "#93DC80", "#42547A"];
      let aColorSet = [].concat(aColors);
      for (var i = 0; i < Math.floor(iLength / aColors.length); i++) {
        aColorSet = aColorSet.concat(aColors);
      }
      return aColorSet;
    },

    sortArrayByDate(aArray, sProp) {
      let aSortedArray = [];
      if (aArray?.length) {
        aSortedArray = [].concat(aArray);
        aSortedArray = aSortedArray.sort((a, b) => this.$moment(b[sProp]).unix() - this.$moment(a[sProp]).unix());
      }
      return aSortedArray;
    },

    checkValidDate(sText) {
      let oDate = this.$moment(sText);
      return sText && oDate.isValid()
    }
  }
};
