<template>
  <div class="map-container">
    <div v-if="isOrderClosed" class="upload-bnt">
      <b-button type="is-small" class="margin-bottom" @click="showArchiveTracking">{{ $t("shipment.tracking.btn.archiveDownload") }}</b-button>
    </div>
    <div class="blur-container">
      <b-switch v-if="!isOrderClosed" v-model="isSwitchedUpdate" size="is-small">{{ $t("shipment.tracking.swch.updateEveryMinute") }}</b-switch>
      <div :class="isMapBlur ? 'blur-map' : ''">
        <gmap-map ref="map" :center="mapCenter" :zoom="6">
          <gmap-polyline v-bind:options="{ strokeColor: '#ff0000' }" :path="waypoints" :stroke-opacity="1.0" :stroke-weight="2"></gmap-polyline>
          <div class="blink">
            <gmap-marker :icon="truckIcon" v-if="waypoints.length > 0" :position="currentPosition" @click="toggleInfoWindow()">

            <gmap-info-window class="auto-tracking-info">
                <div id="station-info-content">                
                  <span class="is-flex info-row">
                    <font-awesome-icon icon="fa-solid fa-clipboard-list" />
                    <h3>{{ formatTextField(orderInternalId) }}</h3>
                  </span>
                  <span class="is-flex info-row">
                    <font-awesome-icon icon="fa-solid fa-truck-front" />
                    <h3>{{ formatTextField(transportNumber) }}</h3>
                  </span>
                  
                </div>
              </gmap-info-window>
            </gmap-marker>        
          </div>
        </gmap-map>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import * as VueGoogleMaps from "vue2-google-maps";
export default {
  props: {
    isTrackingHandle: {
      type: Boolean,
      default: false
    },
    isOrderClosed: {
      type: Boolean
    },
    transportNumber: {
      type: String
    },
    orderInternalId: {
      type: String
    },
    orderWaypoints: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      blinkingAnimation: null,
      markerAnimation: null,
      isLoading: false,
      currentPosition: { lat: 0, lng: 0 },
      isSwitchedUpdate: false,
      timeoutId: undefined,
      isMapBlur: false,
      truckIcon: {
        path: 'M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z',
        fillColor: "#000000",
        fillOpacity: 1,
        scale: 0.05,
        // anchor: new window.google.maps.Point(0, 220)
      },
      waypoints: []
    };
  },
  watch: {
    isSwitchedUpdate(sSwitcherValue) {
      if (sSwitcherValue) {
        this.timeoutId = setInterval(() => {
          this.$emit("autoTrackingUpdate");
        }, 60000);
      } else {
        clearInterval(this.timeoutId);
      }
    },
    isOrderClosed(bClosed) {
      if (bClosed) {
        console.log("isOrderClosed");
        this.isLoading = false;
        this.isMapBlur = true;
        this.waypoints = [];
      }
    },
    orderWaypoints(aWaypoints) {
      this.waypoints = aWaypoints;
      if (!this.isOrderClosed) {
        if (this.waypoints.length > 0) {
          this.displayTracking();
        } else {
          this.isLoading = false;
          this.isMapBlur = false;
        }
      }
    }
  },
  mounted() {
    this.isLoading = true;
  },
  computed: {
    mapCenter() {
      return this.currentPosition;
    },
    bounds() {
      // Define the LatLngBounds object
      const latlngbounds = new window.google.maps.LatLngBounds();

      let lats = Object.values(this.waypoints).map((coord) => coord.lat);
      let lngs = Object.values(this.waypoints).map((coord) => coord.lng);

      const minLat = lats.reduce((min, lat) => Math.min(min, lat), Infinity);
      const maxLat = lats.reduce((max, lat) => Math.max(max, lat), -Infinity);
      const minLng = lngs.reduce((min, lng) => Math.min(min, lng), Infinity);
      const maxLng = lngs.reduce((max, lng) => Math.max(max, lng), -Infinity);

      latlngbounds.extend({ lat: minLat, lng: maxLng }); //SouthEast
      latlngbounds.extend({ lat: maxLat, lng: minLng }); //NorthWest

      return latlngbounds;
    }
  },
  methods: {
    getArchivedTracking() {
      const sShipmentId = this.$route.params.shipmentId;
      this.isLoading = true;
      MockService.getArchiveTracking(sShipmentId)
        .then((oResponse) => {
          this.waypoints = oResponse.data.trackingCoordinates;
          this.currentPosition = this.waypoints[this.waypoints.length - 1];
          this.displayTracking();
        })
        .catch((oError) => {
          if (oError.response.data.errorCode === 502) {
            this.errorMessage(this.$t("shipment.tracking.tryAgain"));
          } else {
            this.errorMessage(this.$t("shipment.tracking.noResult"));
          }
          this.isLoading = false;
          this.isMapBlur = false;
        });
    },
    showArchiveTracking() {
      this.getArchivedTracking();
      
    },
    displayTracking() {
      this.$refs.map.$mapPromise
        .then(() => {
          this.$refs.map.$mapObject.fitBounds(this.bounds);
        })
        .finally(() => {
          this.currentPosition = this.waypoints[this.waypoints.length - 1];
          this.isLoading = false;
          this.isMapBlur = false;
        });
    }
  },
  beforeDestroy() {
    clearInterval(this.timeoutId);
  },
  components: {
    "gmap-map": VueGoogleMaps.Map,
    "gmap-marker": VueGoogleMaps.Marker,
    "gmap-polyline": VueGoogleMaps.Polyline
  }
};
</script>

<style lang="scss">
.map-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  .vue-map-container {
    height: 35rem;
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .vue-map-container {
    height: 15rem;
  }
}
.blur-map {
  filter: blur(5px);
}
.blur-container {
  overflow: hidden;

  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.upload-bnt {
  display: flex;
  justify-content: center;
}
.gm-ui-hover-effect {
  display: none !important;
}

.info-row {
  gap: 1rem;
}

.fa-clipboard-list {
  min-width: 0.723rem;
}

</style>
