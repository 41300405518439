<template>
  <div class="shipments-view">
    <div v-if="isDetail">
      <shipment-detail :shipment="detailedShipment" :selectedShipmentType="selectedShipmentType" :waypoints="waypoints" :isSelectedOrderClosed="isSelectedOrderClosed" @updateTracking="fetchShipment" @trackingCoordinateData="trackingCoordinateData" :selectedCoordinateData="selectedCoordinateData"/>
    </div>
    <div class="colorfullList" v-else>
      <toolbar-panel @onSearch="onSearchTable" @onFilter="onFilterTable" :isFilterApplied="isFilterApplied">
        <template v-slot:left>
          <the-select :sortList="defaultSorters" sortView="shipments" @change="onGetSorterParams" />
        </template>
        <template v-slot:right-begin>
          <div class="clientNameFilter">
            <h4>{{ $t("filters.orderClientName") }}</h4>
            <multiselection-control :items="clientList" :selectedItems="clientControlFilterList" textBinding="clientName" keyBinding="clientId" @change="changeSelectedClientName" width="13rem"></multiselection-control>
          </div>
        </template>
      </toolbar-panel>
      <the-popup ref="popup" :closable="true" height="30rem">
        <the-filter-dialog @filter="onGetFilteringParams" :defaultSettings="defaultFilterSettings" />
      </the-popup>
      <div class="customPagination">
        <div class="pageRecordsCount">
          <h4>{{ $t("shipment.text.numberOfRecords") + ":" }}</h4>
          <b-field>
            <b-radio-button v-for="pageCount in recordCountPerRage" :key="pageCount.key" v-model="pagination" :native-value="pageCount.key" size="is-small" type=" is-primary">
              <span>{{ pageCount.key }}</span>
            </b-radio-button>
          </b-field>
        </div>
        <b-pagination class="customInput" v-if="isPaginationNeeded" :total="filteredList.length" v-model="currentPage" order="is-left" size="is-small" :per-page="pagination" />
      </div>
      <div class="list-items">
        <shipment-item v-show="shipments.length > 0" v-for="shipment in paginatedList" :key="shipment.orderId" :shipment="shipment" @onShipmentClick="onShipmentClick" @shipmentsUpdate="getShipmentsList" />
        <div v-show="shipments.length === 0 && !isLoading" class="no-items">
          {{ $t("shipment.text.noData") }}
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import ToolbarPanel from "@/components/commonLib/ToolbarPanel.vue";
import ShipmentItem from "@/components/shipment/ShipmentItem.vue";
import ShipmentDetail from "@/components/shipment/ShipmentDetail.vue";
import TheFilterDialog from "@/components/commonLib/TheFilterDialog.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import TheSelect from "@/components/commonLib/TheSelect.vue";
import MultiselectionControl from "@/components/commonLib/MultiselectionControl.vue";

export default {
  components: {
    ToolbarPanel,
    ShipmentItem,
    ShipmentDetail,
    TheFilterDialog,
    ThePopup,
    TheSelect,
    MultiselectionControl
  },
  data() {
    return {
      isDetail: false,
      shipments: [],
      pagination: 30,
      clientList: [],
      recordCountPerRage: [{ key: 15 }, { key: 30 }, { key: 50 }],
      isLoading: false,
      detailedShipment: {},
      presetFilters: null,
      presetSorter: this.$store.state.variants.userVariants?.shipments?.sorter ? this.$store.state.variants.userVariants.shipments.sorter : null,
      isFilterApplied: false,
      searchValue: "",
      searchingFields: ["orderNumber", "orderCargoName", "orderFrom", "orderTo", "orderShipper", "orderInternalId", "orderActualStatus.statusText", "orderClientName", "orderKAM"],
      defaultSorters: [
        {
          name: "orderDepartureDateActual,orderDepartureDatePlan",
          active: false,
          key: "orderDepartureDateActual",
          asc: true
        },
        {
          name: "orderArrivalDateActual,orderArrivalDatePlan",
          active: false,
          key: "orderArrivalDatePlan",
          asc: true
        },
        {
          name: "orderActualStatus.statusId",
          active: false,
          key: "orderActualStatus.statusId",
          asc: true
        }
      ],
      defaultFilterSettings: {
        filters: [
          {
            name: "orderDepartureDatePlan",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "orderDepartureDateActual",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "orderArrivalDatePlan",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "orderArrivalDateActual",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "orderFrom",
            type: "input",
            value1: null
          },
          {
            name: "orderTo",
            type: "input",
            value1: null
          },
          {
            name: "orderKAM",
            type: "input",
            value1: null
          }
        ],
        sorters: []
      },
      currentPage: 1,
      clientGroup: [],
      selectedShipmentType: undefined,
      waypoints: [],
      isSelectedOrderClosed: undefined,
      selectedCoordinateData: {},
      clientControlFilterList: []
    };
  },
  computed: {
    filteredList() {
      return this.shipments;
    },
    isPaginationNeeded() {
      return this.pagination !== 0 && this.filteredList.length > this.pagination;
    },
    paginatedList() {
      if (this.isPaginationNeeded) return this.paginate(this.filteredList, this.pagination, this.currentPage);
      else return this.filteredList;
    }
  },
  watch: {
    $route(to) {
      if (to.name === "shipmentDetail") {
        this.isDetail = true;
        this.fetchShipment();
        this.presetFilters = null;
        this.searchValue = "";
      } else {
        this.isDetail = false;
        this.getShipmentsList();
      }
    }
  },
  methods: {
    onSearchTable(oEvent) {
      this.searchValue = oEvent.value;
      this.getShipmentsList();
    },
    onGetFilteringParams(oFilters) {
      this.$refs.popup.close();
      this.presetFilters = oFilters.filters.length > 0 ? oFilters : null;
      this.getShipmentsList();
      this.isFilterApplied = !!this.presetFilters;
    },
    onGetSorterParams(oSorter) {
      this.presetSorter = oSorter;
      this.getShipmentsList();
    },
    getShipmentsList() {      
      var selectedClients = this.clientControlFilterList?.map((client) => client.clientId);
      const clientIds = selectedClients?.join(",");
      if (clientIds) {
        this.isLoading = true;
        MockService.getShipments(clientIds)
          .then((oResponse) => {
            let aShipments = oResponse.data.contactOrders;
            if (this.presetFilters) {
              aShipments = this.filterArray(aShipments, this.presetFilters);
            }
            if (this.searchValue) {
              aShipments = this.searchDataByFields(aShipments, this.searchingFields, this.searchValue);
            }
            if (this.presetSorter) {
              aShipments = this.sortArray(aShipments, this.presetSorter);
            }
            this.shipments = aShipments;  
          })
          .catch((error) => {
            this.errorMessage(error.response?.data.errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onShipmentClick(sId) {
      this.$router.push({
        name: "shipmentDetail",
        params: { shipmentId: sId }
      });
    },
    fetchShipment() {
      const sId = this.$route.params.shipmentId;
      this.isLoading = true;
      MockService.getShipment(sId)
        .then((oResponse) => {
          this.detailedShipment = oResponse.data;
          this.isSelectedOrderClosed = this.detailedShipment?.general?.orderActualStatus?.statusId === 'READY';
          if (!this.isSelectedOrderClosed) {
            this.fetchTracking(this.detailedShipment);
          }
          this.changeShipmentTitle();
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchTracking(oShipment) {
      let getRequest = undefined;
      let getField = undefined;
      if (oShipment?.detail?.orderShipmentType === "Железнодорожная" || oShipment?.detail?.orderShipmentType === "Мультимодальная") {
        this.selectedShipmentType = "railway";
        getRequest = "getRailwayTracking";
        getField = "orderTrackingRailwayList";
      } else {
        this.selectedShipmentType = "auto";
        getRequest = "getShipmentTracking";
        getField = "trackingCoordinates";
      }
      const sShipmentId = this.$route.params.shipmentId;
      MockService[getRequest](sShipmentId)
        .then((oResponse) => {
          this.waypoints = oResponse.data[getField];
          if (this.waypoints.length === 0) {
            this.errorMessage(this.$t("shipment.tracking.noCoordinates"));
          }
        })
        .catch((oError) => {
          this.waypoints = [];
          if (oError.response?.data?.errorCode === 502) {
            this.errorMessage(this.$t("shipment.tracking.tryAgain"));
          } else {
            this.errorMessage(this.$t("shipment.tracking.noResult"));
          }
        });
    },
    changeShipmentTitle() {
      let sTitle = this.$t("title." + this.$route.name, [this.detailedShipment ? this.detailedShipment.general.orderInternalId : ""]);
      this.$store.commit("setHeaderParams", {
        title: sTitle,
        isNavBack: true
      });
    },
    onFilterTable() {
      this.$refs.popup.open();
    },
    changeSelectedClientName(aValue) {
      const oFilter = {
        "userFilter": {
          "clientIds": aValue
        }
      }
      MockService.setControlFilters("shipmentsTable", oFilter)
        .then(() => {
          this.clientControlFilterList = aValue;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.getShipmentsList();            
        });
    },
    getClientGroup() {
      MockService.getClientGroup()
        .then((oResponse) => {
          this.clientList = oResponse.data.clientGroup;
          this.getControlFilters();
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getControlFilters() {
      MockService.getControlFilters("shipmentsTable")
        .then((oResponse) => {
          this.clientControlFilterList = oResponse.data?.userFilter ? oResponse.data.userFilter.clientIds : this.clientList;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    trackingCoordinateData(sCoordinateId) {
      const sShipmentId = this.$route.params.shipmentId;
      MockService.getTrackingCoordinateData(sShipmentId,sCoordinateId)
      .then((oData)=>{
        this.selectedCoordinateData = oData.data;
      })
      .catch((oError)=>{
        if (oError.response.data.errorCode === 502) {
            this.errorMessage(this.$t("shipment.tracking.tryAgain"));
          } else {
            this.errorMessage(this.$t("shipment.tracking.noResult"));
          }        
      })
    }
  },
  created() {
    this.getClientGroup();
    if (this.$route.name === "shipmentDetail") {
      this.isDetail = true;
      this.fetchShipment();
    } else {
      this.onGetSorterParams({
          "name": "orderDepartureDateActual,orderDepartureDatePlan",
          "active": false,
          "key": "orderDepartureDateActual",
          "asc": true
      });
    }
  }
};
</script>

<style lang="scss">
$primaryColor: #2c3e50;

.shipments-view > div {
  display: contents;
  height: -webkit-fill-available;
  height: -moz-available;
}
.shipments-view {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .filterbar {
    width: 100%;
    justify-content: flex-end;
  }
  /*.pagination-btn{

  }*/
  .clientNameFilter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
